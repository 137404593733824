import { languages } from "../../languages";
import "./styles.css";
import ClubCoachCard from "../../components/club-coach-card";
import {useSelector} from "react-redux";
import Button from "../../components/custom-button";
import {useEffect, useState} from "react";
import {clubPage} from "../../API/api";

const ClubsPage = () => {
    const { active_lang } = useSelector((store) => store.language)
    const langData = languages[active_lang].clubsPage
    const btnMore = languages[active_lang]

    const [clubs, setClubs] = useState([])
    const getClubs = async () => {
        const data = await clubPage(active_lang);
        setClubs(data)
    }
    useEffect(() => {
        getClubs();
    },[active_lang]);

    const [loadMoreClubs, setLoadMoreClubs] = useState(3);
    const loadMore = () => {
        setLoadMoreClubs(loadMoreClubs + 3);
    };
  return (
      <div className="clubsPage">
        {clubs.slice(0, loadMoreClubs).map((element) => (
            <ClubCoachCard key={element.id} data={element} dataLang={langData} />
        ))}
          <div className={"btnLoadMore"}>
              <Button
                  className={`${loadMoreClubs >= clubs.length ? "disable" : ""}`}
                  onClick={() => loadMore()}
              >
                  {btnMore.btnMore}
              </Button>
          </div>
      </div>
  )
};

export default ClubsPage;

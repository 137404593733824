import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gallery_id: null,
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setGalleryId: (state, action) => {
      state.gallery_id = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setGalleryId } = modalSlice.actions;

export default modalSlice.reducer;

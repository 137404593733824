export const languages = {
  ru: {
    header: {
      navigation: {
        about: "О нас",
        clubs: "Клубы",
        coaches: "Тренеры",
        calendar: "Календарь турниров",
        rating: "Рейтинг спортсменов",
        news: "Новости",
        gallery: "Фотографии"
      },
      login: "Войти",
      profile: "Профиль",
      logo_text: "Федерация тенниса Кыргызской Республики",
    },
    footer: {
      main_partner: "Официальный партнер федерации",
      sponsor: "Спонсоры и партнеры",
      info_partner: "Информационные партнеры",
      copyright: "Федерация тенниса Кыргызской Республики",
      phone: "Телефон",
      disclaimer: "При использовании материалов ссылка на сайт обязательна.",
    },
    search: {
      title: "Результат поиска по:",
      clubs: "Клубы",
      coaches: "Тренеры",
      calendar: "Календарь",
      rating: "Рейтинг",
      news: "Новости",
      gallery: "Фотогалерея",
      noresult: "По вашему запросу ничего не найдено",
    },
    mainpage: {
      calendar: {
        title: "Календарь турниров",
        btnPrev: "Прошедшие",
        btnNow: "Текущие",
        btnNext: "Следующие",
      },
      rating: {
        title: "Рейтинг спортсменов",
        btnLeft: "Мужчины",
        btnRight: "Женщины",
        param: "Старше 14 лет",
        param2: "До 14 лет",
      },
      news: {
        title: "Новости",
        allNews: "Все новости",
      },
      gallery: {
        title: "Фотогалерея",
        allPhoto: "Все фотографии",
      },
    },
    clubsPage: ["Адрес", "Контакты", "Часы работы", "Соцсети"],
    coachesPage: ["Место работы", "Контакты", "Категория"],
    calendarPage: {
      year: "Год",
      month: "Месяц",
      gender: "Пол",
      age: "Возраст",
      search: "Поиск по названию турнира",
      title: "Название турнира",
      date: "Дата",
      city: "Город",
    },
    ratingPage: {
      gender: "Пол",
      age: "Возраст",
      rating: "Рейтинг",
      full_name: "ФИО",
      birthday: "Год рождения",
      number_of_tournaments: "Кол-во турниров",
      points: "Очки",
      category_age: "Возрастная группа",
    },
    newsPage: {
      news: "Новости",
    },
    itemsSelect: {
      monthList: [
        { id: 1, name: "Все", param: "" },
        { id: 2, name: "Январь", param: "1" },
        { id: 3, name: "Февраль", param: "2" },
        { id: 4, name: "Март", param: "3" },
        { id: 5, name: "Апрель", param: "4" },
        { id: 6, name: "Май", param: "5" },
        { id: 7, name: "Июнь", param: "6" },
        { id: 8, name: "Июль", param: "7" },
        { id: 9, name: "Август", param: "8" },
        { id: 10, name: "Сентябрь", param: "9" },
        { id: 11, name: "Октябрь", param: "10" },
        { id: 12, name: "Ноябрь", param: "11" },
        { id: 13, name: "Декабрь", param: "12" },
      ],
      genderList: [
        {
          id: 1,
          name: "Все",
          param: "",
        },
        {
          id: 2,
          name: "Мужчины",
          param: "male",
        },
        {
          id: 3,
          name: "Женщины",
          param: "female",
        },
      ],
      ageList: [
        {
          id: 1,
          name: "Все",
          param: "",
        },
        {
          id: 2,
          name: "Старше 14",
          param: "14 and older",
        },
        {
          id: 3,
          name: "Младше 14",
          param: "under_14",
        },
      ],
    },
    registerForm: {
      title: "РЕГИСТРАЦИЯ",
      userName: "имя пользователя",
      password: "пароль",
      password2: "ПОВТОРИТЬ пароль",
      password2Err: "Пароли не совпадают",
      lastName: "Фамилия",
      firstName: "Имя",
      city: "город",
      birthdate: "Дата Рождения",
      gender: "Пол",
      male: "Мужчина",
      female: "Женщина",
      phoneNumber: "Контактный номер",
      register: "зарегистрироваться",
      registerEnter: "Уже зарегистрированы?",
      enter: "Войти",
    },
    changePassword: {
      title: "СМЕНА ПАРОЛЯ",
      password: "новый пароль",
      password2: "ПОВТОРИТЬ пароль",
      password2Err: "Пароли не совпадают",
      oldPassword: "старый пароль",
      save: "Сохранить",
      cancel: "Отмена",
    },
    loginPage: {
      title: "вход в Личный КАБИНЕТ",
      username: "имя пользователя",
      password: "пароль",
      login: "Войти",
      register: "регистрация",
    },
    profilePage: {
      title: "Личный кабинет",
      name: "Фамилия Имя",
      birthdate: "Дата рождения",
      city: "Город",
      gender: "Пол",
      male: "Мужчина",
      female: "Женщина",
      phone: "Контактный телефон",
      exit: "Выйти",
    },
    editProfilePage: {
      title: "РЕДАКТИРОВАНИЕ ПРОФИЛЯ",
      changePass: "Сменить пароль",
      lastName: "Фамилия",
      firstName: "Имя",
      city: "город",
      birthdate: "Дата Рождения",
      gender: "Пол",
      male: "Мужчина",
      female: "Женщина",
      contactphone: "Контактный номер",
      save: "Сохранить",
      cancel: "Отмена",
    },
    btnMore: "Загрузить еще",
    hello: "Привет",
  },
  en: {
    header: {
      navigation: {
        about: "About us",
        clubs: "Clubs",
        coaches: "Coaches",
        calendar: "Tournaments calendar",
        rating: "Player ratings",
        news: "News",
        gallery: "Photos"
      },
      login: "Login",
      profile: "Profile",
      logo_text: "Tennis Federation of the Kyrgyz Republic",
    },
    footer: {
      main_partner: "Official partner of the federation",
      sponsor: "Sponsors and partners",
      info_partner: "Information partners",
      copyright: "Tennis Federation of the Kyrgyz Republic",
      phone: "Phone number",
      disclaimer: "Reference required for material use.",
    },
    search: {
      title: "search result by:",
      clubs: "Clubs",
      coaches: "Coaches",
      calendar: "Calendar",
      rating: "Rating",
      news: "News",
      gallery: "Photogallery",
      noresult: "Nothing found for your request",
    },
    mainpage: {
      calendar: {
        title: "Tournaments calendar",
        btnPrev: "Past",
        btnNow: "Current",
        btnNext: "Next",
      },
      rating: {
        title: "Player ratings",
        btnLeft: "Men",
        btnRight: "Women",
        param: "Over 14",
        param2: "14 & under",
      },
      news: {
        title: "News",
        allNews: "All news",
      },
      gallery: {
        title: "Photogallery",
        allPhoto: "All photos",
      },
    },
    clubsPage: ["Address", "Contacts", "Working hours", "Social links"],
    coachesPage: ["Working place", "Contacts", "Category"],
    calendarPage: {
      year: "Year",
      month: "Month",
      gender: "Gender",
      age: "Age",
      search: "Search by tournament name",
      title: "Tournament",
      date: "Date",
      city: "City",
    },
    ratingPage: {
      gender: "Gender",
      age: "Age",
      rating: "Rating",
      full_name: "Full name",
      birthday: "DOB",
      number_of_tournaments: "Events played",
      points: "Points",
      category_age: "Age group",
    },
    newsPage: {
      news: "News",
    },
    itemsSelect: {
      monthList: [
        { id: 1, name: "All", param: "" },
        { id: 2, name: "January", param: 1 },
        { id: 3, name: "February", param: 2 },
        { id: 4, name: "March", param: 3 },
        { id: 5, name: "April", param: 4 },
        { id: 6, name: "May", param: 5 },
        { id: 7, name: "June", param: 6 },
        { id: 8, name: "July", param: 7 },
        { id: 9, name: "August", param: 8 },
        { id: 10, name: "September", param: 9 },
        { id: 11, name: "October", param: 10 },
        { id: 12, name: "November", param: 11 },
        { id: 13, name: "December", param: 12 },
      ],
      genderList: [
        {
          id: 1,
          name: "All",
          param: "",
        },
        {
          id: 2,
          name: "Men",
          param: "male",
        },
        {
          id: 3,
          name: "Women",
          param: "female",
        },
      ],
      ageList: [
        {
          id: 1,
          name: "All",
          param: "",
        },
        {
          id: 2,
          name: "14 and above",
          param: "14 and older",
        },
        {
          id: 3,
          name: "Under 14",
          param: "under_14",
        },
      ],
    },
    registerForm: {
      title: "REGISTRATION",
      userName: "Username",
      password: "Password",
      password2: "Repeat password",
      password2Err: "Passwords don't match",
      lastName: "Last name",
      firstName: "First name",
      city: "City",
      birthdate: "Birthdate",
      gender: "Gender",
      male: "Man",
      female: "Woman",
      phoneNumber: "Contact number",
      register: "Register",
      registerEnter: "Already registered?",
      enter: "Login",
    },
    changePassword: {
      title: "PASSWORD CHANGE",
      password: "New password",
      password2: "Repeat password",
      password2Err: "Passwords don't match",
      oldPassword: "old password",
      save: "Save",
      cancel: "Cancel",
    },
    loginPage: {
      title: "entrance to the profile",
      username: "Username",
      password: "Password",
      login: "Login",
      register: "Registration",
    },
    profilePage: {
      title: "Profile",
      name: "Last name First name",
      birthdate: "Birthdate",
      city: "City",
      gender: "Gender",
      male: "Man",
      female: "Woman",
      phone: "Contact number",
      exit: "Exit",
    },
    editProfilePage: {
      title: "Edit profile",
      changePass: "Change password",
      lastName: "Last name",
      firstName: "First name",
      city: "City",
      birthdate: "Birthdate",
      gender: "Gender",
      male: "Man",
      female: "Woman",
      contactphone: "Contact number",
      save: "Save",
      cancel: "Cancel",
    },
    btnMore: "Load more",
    hello: "Hello",
  },
  ky: {
    header: {
      navigation: {
        about: "Биз жѳнүндѳ",
        clubs: "Клубдар",
        coaches: "Машыктыруучулар",
        calendar: "Оюндар календары",
        rating: "Оюнчулар рейтинги",
        news: "Жаңылыктар",
        gallery: "Сүрѳттѳр"
      },
      login: "Кирүү",
      profile: "Профиль",
      logo_text: "Кыргыз Республикасынын Теннис Федерациясы",
    },
    footer: {
      main_partner: "Федерациянын расмий өнөктөшү",
      sponsor: "Демѳѳрчүлѳр жана өнөктөштѳр",
      info_partner: "Маалымат өнөктөштѳр",
      copyright: "Кыргыз Республикасынын Теннис Федерациясы",
      phone: "Тел номери",
      disclaimer: "Материалдарды пайдаланган кезде милдеттүү түрдѳ расмий баракчага шилтеме жасоо зарыл.",
    },
    search: {
      title: "Издөөнүн жыйынтыгы:",
      clubs: "Клубдар",
      coaches: "Тренерлер",
      calendar: "Календарь",
      rating: "Рейтинг",
      news: "Жаңылыктар",
      gallery: "Сүрөт галерея",
      noresult: "Сурамыңыз боюнча эч нерсе табылган жок",
    },
    mainpage: {
      calendar: {
        title: "Оюндар календары",
        btnPrev: "Өткөн",
        btnNow: "Учурдагы",
        btnNext: "Кийинки",
      },
      rating: {
        title: "Оюнчулар рейтинги",
        btnLeft: "Эркектер",
        btnRight: "Аялдар",
        param: "14 жаштан ѳйдѳ",
        param2: "14 жаш жана кичүү",
      },
      news: {
        title: "Жаңылыктар",
        allNews: "Бардык жаңылыктар",
      },
      gallery: {
        title: "Сүрѳт галереясы",
        allPhoto: "Бардык сүрѳттѳр",
      },
    },
    clubsPage: ["Дареги", "Байланыш", "Иш убактысы", "Социалдык тармактар"],
    coachesPage: ["Иштеген жери", "Байланыш", "Категория"],
    calendarPage: {
      year: "Жылы",
      month: "Айы",
      gender: "Жынысы",
      age: "Жашы",
      search: "Турнир аталышын издөө",
      title: "Турнирдин аталышы",
      date: "Дата",
      city: "Шаар",
    },
    ratingPage: {
      gender: "Эркектер",
      age: "Жашы",
      rating: "Рейтинг",
      full_name: "Толук аты",
      birthday: "Туулган жылы",
      number_of_tournaments: "Турнир саны",
      points: "Упай",
      category_age: "Жаш курагы",
    },
    newsPage: {
      news: "Жаңылыктар",
    },
    itemsSelect: {
      monthList: [
        { id: 1, name: "Баары", param: "" },
        { id: 2, name: "Январь", param: 1 },
        { id: 3, name: "Февраль", param: 2 },
        { id: 4, name: "Март", param: 3 },
        { id: 5, name: "Апрель", param: 4 },
        { id: 6, name: "Май", param: 5 },
        { id: 7, name: "Июнь", param: 6 },
        { id: 8, name: "Июль", param: 7 },
        { id: 9, name: "Август", param: 8 },
        { id: 10, name: "Сентябрь", param: 9 },
        { id: 11, name: "Октябрь", param: 10 },
        { id: 12, name: "Ноябрь", param: 11 },
        { id: 13, name: "Декабрь", param: 12 },
      ],
      genderList: [
        {
          id: 1,
          name: "Баары",
          param: "",
        },
        {
          id: 2,
          name: "Эркектер",
          param: "male",
        },
        {
          id: 3,
          name: "Аялдар",
          param: "female",
        },
      ],
      ageList: [
        {
          id: 1,
          name: "Баары",
          param: "",
        },
        {
          id: 2,
          name: "14 жана жогору",
          param: "14 and older",
        },
        {
          id: 3,
          name: "14 жашка чейин",
          param: "under_14",
        },
      ],
    },
    registerForm: {
      title: "Каттоо",
      userName: "Колдонуучунун аты",
      password: "Сырсөз",
      password2: "Сырсөздү кайталаңыз",
      password2Err: "Сырсөздөр дал келбейт",
      lastName: "Фамилия",
      firstName: "Аты",
      city: "Шаар",
      birthdate: "Туулган күнү",
      gender: "Жынысы",
      male: "Эркек",
      female: "Аял",
      phoneNumber: "Байланыш номери",
      register: "Катталуу",
      registerEnter: "Катталгансызбы?",
      enter: "Кирүү",
    },
    changePassword: {
      title: "СӨРДҮ ӨЗГӨРТҮҮ",
      password: "жаңы сыр сөз",
      password2: "Сырсөздү кайталаңыз",
      password2Err: "Сырсөздөр дал келбейт",
      oldPassword: "Эски Сырсөз",
      save: "Сактоо",
      cancel: "Артка",
    },
    loginPage: {
      title: "Жеке кабинетке кирүү ",
      username: "Колдонуучунун аты",
      password: "Сырсөз",
      login: "Кирүү",
      register: "Каттоо",
    },
    profilePage: {
      title: "Жеке кабинет",
      name: "Фамилия Аты",
      birthdate: "Туулган күнү",
      city: "Шаар",
      gender: "Жынысы",
      male: "Эркек",
      female: "Аял",
      phone: "Байланыш номери",
      exit: "Чыгуу",
    },
    editProfilePage: {
      title: "ПРОФИЛДИ ОҢДОО",
      changePass: "СӨРДҮ ӨЗГӨРТҮҮ",
      lastName: "Фамилия",
      firstName: "Аты",
      city: "Шаар",
      birthdate: "Туулган күнү",
      gender: "Жынысы",
      male: "Эркек",
      female: "Аял",
      contactphone: "Байланыш номери",
      save: "Сактоо",
      cancel: "артка",
    },
    btnMore: "Көбүрөөк жүктөө",
    hello: "Салам",
  },
};

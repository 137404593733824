import './style.css'
import {useForm} from "react-hook-form";
import {ArrowRight} from "react-feather";
import {Link, useNavigate} from "react-router-dom";
import {TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {loginUser} from "../../API/api";
import {useSelector} from "react-redux";
import {languages} from "../../languages";

const LoginPage = () => {
    //const [valuePassword, setValuePassword] = useState('');
    const [error, setError] = useState('')

    const {active_lang} = useSelector((store) => store.language)
    const langData = languages[active_lang].loginPage
    const {
        handleSubmit,
        register,
        reset,
        formState: {errors}
    } = useForm();
    const navigate = useNavigate()

    const tokenChk = () => {
        const token = localStorage.getItem("token")
        if (token) {
            navigate("/profile")
        }
    }
    useEffect(() => {
        tokenChk()
    }, [])

    const onSubmit = async (data) => {
        if (errors?.name) {
            console.log(errors?.name?.message)
        }
        try {
            const result = await loginUser(data, active_lang)
            localStorage.setItem('token', result.refresh)
            localStorage.setItem('Access', result.access)
            reset()
            navigate("/profile")
        } catch (error) {
            console.dir(error);
            setError(JSON.parse(error.request.responseText));
            throw new Error("Что-то не так с пост запросом");
        }
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const onKeyDown = e => {
            if(e.keyCode === 13) {
                handleSubmit(onSubmit);
            }
        };
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    return (
        <div className="login container">
            <h2>{langData.title}</h2>
            <div className="login-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="register-form">
                        <TextField
                            className="register-form-input"
                            type="text"
                            label={langData.username}
                            variant="outlined"
                            error={error.username ? true : false}
                            helperText={error.username ? `${error?.username}` : ""}
                            {...register("username", { required: true })} />
                        <TextField
                            className="register-form-input"
                            type="password"
                            label={langData.password}
                            variant="outlined"
                            error={error.password ? true : false}
                            helperText={error.password ? `${error?.password}` : ""}
                            {...register("password", { required: true })}
                    />
                    </div>
                    <div className="register-form-input" style={{color: "red"}}>{error.detail ? `${error?.detail}` : ""}</div>
                    <div className="login-enter">
                    <button className="login-enter-btn">
                        {langData.login} <ArrowRight width="16px" height="12px"/>{" "}
                    </button>
                    <div className="login-register"><Link to={"/register"}>{langData.register}</Link></div>
                </div>
                </form>
            </div>

        </div>
    )
}

export default LoginPage
import "./style.css"
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {searchHeader} from "../../API/api";
import {useSelector} from "react-redux";
import NewsCard from "../../components/news-card";
import GalleryCard from "../../components/gallery-card";
import ClubCoachCard from "../../components/club-coach-card";
import {languages} from "../../languages";
import CalendarMainPageCard from "../../components/calendar-MainPage-card";

const SearchPage = () => {
    const { query } = useParams();
    const { active_lang } = useSelector((store) => store.language);
    const langSearchData = languages[active_lang].search
    const langData = languages[active_lang].clubsPage
    const langSelectData = languages[active_lang].itemsSelect

    const [params, setParams] = useState()
    const [searchResult, setSearchResult] = useState([])
    const getSearchResult = async () => {
        const data = await searchHeader(active_lang, params);
        setSearchResult(data)
    }
    useEffect(() => {
        if (params) {
            getSearchResult();
        }
    },[params]);
    useEffect(() => {
        setParams(`?query=${query}`)
    },[query])

    // console.log(searchResult)
    return (
        <div className="searchPage container">
            <h2>{langSearchData.title} {query}</h2>
            {searchResult?.news?.length > 0 &&
                <>

                    <div className="search-each">
                        <h3>{langSearchData.news}</h3>
                        <div className="news-cards-small">
                            {searchResult?.news?.slice(0, 3).map((element) => (
                                <NewsCard key={element.id} dataNews={element} ratio={"4/3"} bgWhite={true} short={true} />
                            ))}
                        </div>
                    </div>
                </>
            }
            {searchResult?.gallery?.length > 0 &&
                <>
                    <div className="search-each">
                        <h3>{langSearchData.gallery}</h3>
                        <div className="gall-cards">
                            {searchResult?.gallery?.slice(0, 3).map((element) => (
                                <GalleryCard key={element.id} dataGall={element} />
                            ))}
                        </div>
                    </div>
                </>
            }
            {searchResult?.club?.length > 0 &&
                <>
                    <div className="search-each">
                        <h3>{langSearchData.clubs}</h3>
                        {searchResult?.club?.slice(0, 3).map((element) => (
                            <ClubCoachCard key={element.id} data={element} dataLang={langData} />
                        ))}
                    </div>
                </>
            }
            {searchResult?.trainer?.length > 0 &&
                <div className="search-each">
                    <h3>{langSearchData.coaches}</h3>
                    {searchResult?.trainer?.slice(0, 3).map((element) => (
                        <ClubCoachCard key={element.id} data={element} dataLang={langData} coaches={true}/>
                    ))}
                </div>
            }
            {searchResult?.calendar?.length > 0 &&
                <>
                    <div className="search-each">
                        <h3>{langSearchData.calendar}</h3>
                        <div className="calendar-page-main">
                            {searchResult?.calendar?.slice(0, 3).map((element) => (
                                <div className="calendar-page_data calendar-data-text" key={element.id}>
                                    <span className="calendar-page_name">{element.translations[active_lang].name}</span>
                                    <span className="calendar-page_date">{`${new Date(element.start_date).getDay()}.${new Date(element.start_date).getMonth()}`} - {element.end_date.split("-").reverse().join(".")}</span>
                                    <span className="calendar-page_city">{element.translations[active_lang].location}</span>
                                </div>
                            ))}
                        </div>
                        <div className={"event-mainPage small-calendar"}>
                            {searchResult?.calendar?.slice(0, 3).map((element) => (
                                <CalendarMainPageCard key={element.id} dataEvents={element} />
                            ))}
                        </div>
                    </div>
                </>
            }
            {searchResult?.rating?.length > 0 &&
                <>
                    <div className="search-each">
                        <h3>{langSearchData.rating}</h3>
                        <div className="rating-page-main">
                            {searchResult?.rating?.slice(0, 3).map((element) => (
                                <div className="rating-page_data rating-data-text" key={element.id}>
                                    <span className="rating-text1-width rating-text-center">{element.rating}</span>
                                    <span className="rating-text2-width">{element.translations[active_lang].full_name}</span>
                                    <span className="rating-text3-width rating-text-center">{element.birth_date}</span>
                                    <span className="rating-page_tournament rating-text3-width rating-text-center">{element.number_of_tournaments}</span>
                                    <span className="rating-page_points rating-text3-width rating-text-center">{element.points}</span>
                                    <span className="rating-page_age rating-text3-width rating-text-center">
                          {element.translations[active_lang].category_age === langSelectData.ageList[2].param ?
                              langSelectData.ageList[2].name :
                              langSelectData.ageList[1].name}
                        </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            }
            {
                searchResult?.news?.length === 0 &&
                searchResult?.gallery?.length === 0 &&
                searchResult?.club?.length === 0 &&
                searchResult?.trainer?.length === 0 &&
                searchResult?.calendar?.length === 0 &&
                searchResult?.rating?.length === 0 &&
                <div className="search-each">
                    <h3>{langSearchData.noresult}</h3>
                </div>
            }
        </div>
    )
}

export default SearchPage
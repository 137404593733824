import { languages } from "../../languages";
import "./styles.css";
import SelectBlue from "../../components/select-blue";
import {useEffect, useRef, useState} from "react";
import Button from "../../components/custom-button";
import {useSelector} from "react-redux";
import {ratingPage} from "../../API/api";


const RatingPage = () => {
  const ref = useRef()
  const { active_lang } = useSelector((store) => store.language)
  const langSelectData = languages[active_lang].itemsSelect
  const langData = languages[active_lang].ratingPage
  const btnMore = languages[active_lang]

  const genderList = langSelectData.genderList
  const ageList = langSelectData.ageList

  const [gender, setGender ] = useState('')
  const [age, setAge] = useState('')

  //Get data from host
  const [params, setParams] = useState()
  const [rating, setRating] = useState([])

  const getRating = async () => {
    const data = await ratingPage(params, active_lang);
    setRating(data)
  }
  useEffect(() => {
    getRating();
  },[params]);
  useEffect(() => {
    setParams({
      category_gender: gender,
      category_age: age,
        }
    )
  },[gender, age])

  const [loadMoreRating, setLoadMoreRating] = useState(14);
  const loadMore = () => {
    setLoadMoreRating(loadMoreRating + 3);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
      <div className="ratingPage container" ref={ref}>
        <div className="head-menu-rating">
          <SelectBlue
              defaultSelectText={langData.gender}
              optionList={genderList}
              onChange={setGender}
              customRef={ref}
          />
          <SelectBlue
              defaultSelectText={langData.age}
              optionList={ageList}
              onChange={setAge}
              customRef={ref}
          />
        </div>
        <div className="rating-page-main">
          <div className="rating-page_data rating-head-text rating-text-center">
            <span className="rating-text1-width">{langData.rating}</span>
            <span className="rating-text2-width">{langData.full_name}</span>
            <span className="rating-text3-width">{langData.birthday}</span>
            <span className="rating-page_tournament rating-text3-width">{langData.number_of_tournaments}</span>
            <span className="rating-page_points rating-text3-width">{langData.points}</span>
            <span className="rating-page_age rating-text3-width">{langData.category_age}</span>
          </div>

          {rating.slice(0, loadMoreRating).map((element) => (
              <div className="rating-page_data rating-data-text" key={element.id}>
                <span className="rating-text1-width rating-text-center">{element.rating}</span>
                <span className="rating-text2-width">{element.translations[active_lang].full_name}</span>
                <span className="rating-text3-width rating-text-center">{element.birth_date}</span>
                <span className="rating-page_tournament rating-text3-width rating-text-center">{element.number_of_tournaments}</span>
                <span className="rating-page_points rating-text3-width rating-text-center">{element.points}</span>
                <span className="rating-page_age rating-text3-width rating-text-center">
                  {element.translations[active_lang].category_age === langSelectData.ageList[2].param ?
                      langSelectData.ageList[2].name :
                      langSelectData.ageList[1].name}
                </span>
              </div>
          ))}
        </div>
        <div className={"btnLoadMore"}>
          <Button
              className={`${loadMoreRating >= rating.length && "disable"}`}
              onClick={() => loadMore()}
          >
            {btnMore.btnMore}
          </Button>
        </div>
      </div>
  )
}

export default RatingPage;

import axios from "axios";

export const request = async (
  endpoint,
  method,
  data = null,
  params = null,
  header = null,
) => {
  // const result = await axios(`http://44.240.215.224/${endpoint}`, {
  const result = await axios(`https://api.ftkr.kg/${endpoint}`, {
    method: method,
    data: data,
    params: params,
    headers: header,
  }).then((res) => res.data);
  //console.log(result)
  return result;
};

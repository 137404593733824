import CalendarMainPageCard from "../../../components/calendar-MainPage-card";
import "./style.css";
import {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../languages";
import { currentCalendarPage, futureCalendarPage, pastCalendarPage} from "../../../API/api";

const MainPageCalendar = () => {
  const [currentCalendar, setCurrentCalendar] = useState([]);
  // const [params, setParams] = useState({start_month: `${new Date().getMonth() + 1}`})
  const { active_lang } = useSelector((store) => store.language);
  const [btnChoise, setBtnChoise] = useState(2)

    // const getCalendar = async () => {
    //     const data = await calendarPage(params, active_lang);
    //     setCurrentCalendar(data)
    // }
    const getCalendar = async () => {
        if (btnChoise === 1) {
            const data = await pastCalendarPage(active_lang)
            setCurrentCalendar(data)
        }
        if (btnChoise === 2) {
            const data = await currentCalendarPage(active_lang)
            setCurrentCalendar(data)
        }
        if (btnChoise === 3) {
            const data = await futureCalendarPage(active_lang)
            setCurrentCalendar(data)
        }
    }
    useEffect(() => {
        getCalendar();
    },[btnChoise]);

  const langData = languages[active_lang].mainpage.calendar;

  return (
    <div className="mainPageCalendar">
      <h2>{langData.title}</h2>
      <div className={"btn-Main-Blue-Red"}>
          <div className={"btn-blue-red_three-btn"}>
              <div className={`btn-blue-red_btn_three-btn ${btnChoise === 1 && "btnRedColor"}`} onClick={() => setBtnChoise(1)}>
                  <span>{langData.btnPrev}</span>
              </div>
              <div className={`btn-blue-red_btn_three-btn ${btnChoise === 2 && "btnRedColor"}`} onClick={() => setBtnChoise(2)}>
                  <span>{langData.btnNow}</span>
              </div>
              <div className={`btn-blue-red_btn_three-btn ${btnChoise === 3 && "btnRedColor"}`} onClick={() => setBtnChoise(3)}>
                  <span>{langData.btnNext}</span>
              </div>
          </div>
      </div>
      <div className={"event-mainPage"}>
        {currentCalendar?.slice(0, 3).map((element) => (
          <CalendarMainPageCard key={element.id} dataEvents={element} />
        ))}
      </div>
    </div>
  );
};

export default MainPageCalendar;

import "./style.css"
import {Info, MapPin} from "react-feather";
import {useSelector} from "react-redux";

const CalendarMainPageCard = ({ dataEvents }) => {
    const { active_lang } = useSelector((store) => store.language)
    const arrStartDate = dataEvents.start_date.split('-')
    // console.log(dataEvents.start_date.split('-'))
    return (
        <div className={"events-card"}>
            <div className="event-info">{`${arrStartDate[2]}.${arrStartDate[1]}`} - {dataEvents.end_date.split("-").reverse().join(".")}</div>
            <span>
                <a href={`${dataEvents.regulations}`} target={"_blank"}>
                    {dataEvents.translations[active_lang].name} <Info size={15} />
                </a>
            </span>
            <div className="event-info"><MapPin height={"14.5px"} /> {dataEvents.translations[active_lang].location}</div>
            <div className="event-line"></div>
        </div>
    )
}

export default CalendarMainPageCard
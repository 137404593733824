import { useDispatch } from "react-redux";
import { setGalleryId } from "../../store/modal";
import React, {useEffect, useRef} from "react";
import login from "../../store/login";

const SliderGalleryCard = (props) => {
  const { data, dataIndex } = props;
  // const { main_image } = data[dataIndex];
  const dispatch = useDispatch();
  const refImg = useRef();

  useEffect(() => {
      let flag = 1;
      const handleClick = (e) => {
          // // console.log(ref2.current)
          // if (refImg.current) return /*(console.log(ref2.current.contains(e.target)))*/
          // console.log(data[dataIndex])
          // if (refImg.current.contains(e.target) && flag === 0) {
          //     // dispatch(setGalleryId(data[dataIndex]))
          //     console.log(222)
          // }

          if (refImg.current && data && dataIndex) {
              // console.log(1)
              // console.log(refImg.current.contains(e.target))
              // console.log(e.target)
              if (refImg.current.contains(e.target) && flag === 0) {
                  // console.log(2)
                  dispatch(setGalleryId(data[dataIndex]))
              }
          }

      };
      window.addEventListener("mousedown", () => {
          flag = 0;
      });
      window.addEventListener("mousemove", () => {
          flag = 1;
      });
      window.addEventListener("mouseup", handleClick);
      return () => {
          document.removeEventListener("mousedown", handleClick);
          document.removeEventListener("mousemove", handleClick);
          document.removeEventListener("mouseup", handleClick);
      };
  },[data])
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        userSelect: "none",
      }}
      className="my-slide-component"
      // ref={refImg}
    >
      <img
        // onClick={() => dispatch(setGalleryId(data[dataIndex]))}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={data[dataIndex]?.main_image}
        alt="img"
        ref={refImg}
      />
    </div>
  );
};

export default SliderGalleryCard;

import './style.css'
import {footerData} from "../../utils/FooterData/ftData";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {languages} from "../../languages";
import {informationalpartners, officialpartner, sponsorsandpartners} from "../../API/api";

const MainPageSponsor = () => {
    const { active_lang } = useSelector((store) => store.language)
    const langData = languages[active_lang].footer

    //Official partner
    const [officialPartner, setOfficialPartner] = useState([])
    const getOfficialPartner = async () => {
        const data = await officialpartner(active_lang);
        setOfficialPartner(data[0])
    }

    //sponsors & partners
    const [sponsorPartner, setSponsorPartner] = useState([])
    const getSponsorPartner = async () => {
        const data = await sponsorsandpartners(active_lang);
        setSponsorPartner(data)
    }

    // Inform partner
    const [informPartner, setInformPartner] = useState([])
    const getInformPartner = async () => {
        const data = await informationalpartners(active_lang);
        setInformPartner(data)
    }
    useEffect(() => {
        getOfficialPartner();
        getSponsorPartner();
        getInformPartner();
    },[]);

    return (
        <section className="footer-section">
            <div className="footerSpace" />
            <div className="partners container">
                <div className="officialPartner">
                    <h3>{langData.main_partner}</h3>
                        <img className="logo" src={officialPartner?.images} alt="" />
                    {/*<img className="logo" src={footerData[0].linkImg} alt="" />*/}
                </div>
                <div className="otherPartners">
                    <div className="sponsors">
                        <h3>{langData.sponsor}</h3>
                        <div className="logo">
                            {sponsorPartner?.map((el) => (
                                <div key={el.id} className="logo-box">
                                    <img src={el.images} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="sponsors">
                        <h3>{langData.info_partner}</h3>
                        <div className="logo">
                            {informPartner?.map((el) => (
                                <div key={el.id} className="logo-box">
                                    <img src={el.images} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainPageSponsor
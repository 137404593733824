import { useEffect, useState} from "react";
import './style.css'

const SelectBlue = ({optionList, defaultSelectText, onChange, customRef}) => {

    const ref = customRef;

    const [showOptionList, setShowOptionList] = useState(false)

    const [selectText, setSelectText] = useState(defaultSelectText)

    const handleListDisplay = () => {
        setShowOptionList(!showOptionList)
    }

    const handleOptionClick = (e) => {
        setSelectText(e.target.getAttribute("value-name"))
        onChange(e.target.getAttribute("data-name"))
        setShowOptionList(false)
    }


    useEffect(() => {
        if (!showOptionList) return;

        const handleClick = (e) => {
            if (!ref.current) return
            if (!ref.current.contains(e.target)) {
                setShowOptionList(false)
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [showOptionList]);

    return (
            <div className="custom-select-container">
                <div
                    className={showOptionList ? "selected-text active" : "selected-text"}
                    onClick={handleListDisplay}
                >
                    {selectText}
                </div>
                {showOptionList && (
                    <ul className="select-options">
                        {optionList.map(option => {
                            return (
                                <li
                                    className="custom-select-option"
                                    data-name={option.param}
                                    value-name={option.name}
                                    key={option.id}
                                    onClick={handleOptionClick}
                                >
                                    {option.name}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
    )
}

export default SelectBlue
import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./language";
import modalReducer from "./modal";
//import loginReducer from "./login";

export const store = configureStore({
  reducer: {
    language: languageReducer,
    modal: modalReducer,
    //login: loginReducer,
  },
});

import { languages } from "../../languages";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowLeft } from "react-feather";
import NewsCard from "../../components/news-card";
import { useSelector } from "react-redux";
import { newsPage, singleNewsPage } from "../../API/api";

const SingleNewsPage = () => {
  const { id } = useParams();
  const [newsId, setNewsId] = useState(id);
  const [news, setNews] = useState([]);
  const [singleNews, setSingleNews] = useState([]);


    const { active_lang } = useSelector((store) => store.language);
    const langData = languages[active_lang].newsPage;

  useEffect(() => {
    if (id !== newsId) {
      setNewsId(id);
    }
  }, [id]);

    const params = {ordering: "-date"}
  const getNews = async () => {
    const data = await newsPage(active_lang, params);
    setNews(data);
  };
  useEffect(() => {
    getNews();
  }, [id]);

  const getSingleNews = async () => {
    const data = await singleNewsPage(newsId, active_lang);
    setSingleNews(data);
  };
    useEffect(() => {
        getSingleNews();
    },[newsId]);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [newsId]);

  return (
    <div className="newsSinglePage container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
        }}
        onClick={() => navigate("/news")}
      >
        <ArrowLeft />
        <h2>{langData.news}</h2>
      </div>
      <img src={singleNews.main_photo} alt="element" />
      <div className="news-single-title">{singleNews?.translations?.[active_lang].name}</div>
      <div className="news-single-date">{singleNews.date}</div>
      <div className="news-single-text">{singleNews?.translations?.[active_lang].description}</div>
      <div className="news-other">
        <div className="news-cards-small">
          {news.slice(0, 3).map((element) => (
            <NewsCard key={element.id} dataNews={element} ratio={"4/3"} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleNewsPage;

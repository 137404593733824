import NewsCard from "../../components/news-card";
import "./style.css";
import {useNavigate} from "react-router-dom";
import { languages } from "../../languages";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {newsPage} from "../../API/api";

const MainPageNews = () => {
    const navigate = useNavigate()
    const { active_lang } = useSelector((store) => store.language)
    const langData = languages[active_lang].mainpage.news

    const params = {ordering: "-date"}
    const [news, setNews] = useState([])
    const getNews = async () => {
        const data = await newsPage(active_lang, params);
        setNews(data)
    }
    useEffect(() => {
        getNews();
    },[]);
    return (
        <div className="mainPageNews container">
            <h2>{langData.title}</h2>
            <div className={"allNews"} onClick={() => navigate('/news')}>{langData.allNews}</div>
            <div className="mainPageNews-cards-small">
                {news.slice(0, 4).map((element) => (
                    <NewsCard key={element.id} dataNews={element} ratio={"4/3"} bgWhite={true} short={true} />
                ))}
            </div>
        </div>
    )
}

export default MainPageNews
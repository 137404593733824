import "./styles.css";
import MainPageNews from "../../modules/main-page-news/main-page-news";
import MainPageRatingCalendar from "../../modules/main-page-rating";
import MainGallerySlider from "../../modules/main-gallery-slider";
import "./styles.css"
import {useEffect, useState} from "react";
import {mainPage, token} from "../../API/api";
import {useSelector} from "react-redux";
import MainPageSponsor from "../../modules/main-page-sponsor";

const MainPage = () => {
    const { active_lang } = useSelector((store) => store.language);
    const [main, setMain] = useState([])
    const getMainPage = async () => {
        const data = await mainPage(active_lang);
        setMain(data[0])
    }
    useEffect(() => {
        getMainPage();
    },[]);


  return (
    <div className="mainPage">
      <img src={`${main?.main_photo}`} alt="" />
      <MainPageRatingCalendar />
      <MainPageNews />
      <MainGallerySlider />
      <MainPageSponsor />
    </div>
  );
};

export default MainPage;

import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {languages} from "../../../languages";
import {Link, useNavigate} from "react-router-dom";
import {changeLanguage} from "../../../store/language";
import "./menu.css"
import {X} from "react-feather";


const Menu = ({active, setActive, customRef}) => {
    const dispatch = useDispatch();
    const { active_lang } = useSelector((store) => store.language);
    const { header } = languages[active_lang];
    const navigate = useNavigate();
    const ref = customRef;

    const menuClick = (key) => {
        navigate(`/${key}`)
        setActive(false)
    }

    useEffect(() => {
        if (!active) return;

        const handleClick = (e) => {
            if (!ref.current) return
            if (!ref.current.contains(e.target)) {
                setActive(false)
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [active]);
    return (
        <div ref={ref} className={active ? 'menu active' : 'menu'}>
            <div className="menu__content">
                <div className="menu_close" onClick={() => setActive(false)}><X/></div>
                <div className="menu__links">
                {Object.keys(header.navigation).map((key, index) => {
                    return (
                        // <Link key={index} to={`/${key}`}>
                        //     {header.navigation[key]}
                        // </Link>
                        <div key={index} onClick={() => menuClick(key)}>
                            {header.navigation[key]}
                        </div>
                    );
                })}
                </div>
                {/*<hr/>*/}

                <div
                    className="lang">
                    <span onClick={(e) => dispatch(changeLanguage("ru"))}>Русский</span>
                    <span onClick={(e) => dispatch(changeLanguage("ky"))}>Кыргызский</span>
                    <span onClick={(e) => dispatch(changeLanguage("en"))}>Английский</span>
                </div>
            </div>


        </div>
    );
};

export default Menu;
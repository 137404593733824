import { languages } from "../../languages";
import "./styles.css";
import SelectBlue from "../../components/select-blue";
import {useEffect, useRef, useState} from "react";
import Button from "../../components/custom-button";
import CalendarMainPageCard from "../../components/calendar-MainPage-card";
import {useSelector} from "react-redux";
import {calendarPage} from "../../API/api";
import { Info } from 'react-feather';

const CalendarPage = () => {
    const ref = useRef()
    const { active_lang } = useSelector((store) => store.language)
    const langSelectData = languages[active_lang].itemsSelect
    const langData = languages[active_lang].calendarPage
    const btnMore = languages[active_lang]

    const monthList = langSelectData.monthList
    // const genderList = langSelectData.genderList
    // const ageList = langSelectData.ageList

    const yearList= [
        { id: 1, name: `${new Date().getFullYear() + 2}`, param: `${new Date().getFullYear() + 2}`},
        { id: 2, name: `${new Date().getFullYear() + 1}`, param: `${new Date().getFullYear() + 1}`},
        { id: 3, name: `${new Date().getFullYear()}`, param: `${new Date().getFullYear()}`},
        { id: 4, name: `${new Date().getFullYear() -1}`, param: `${new Date().getFullYear() - 1}`}
    ]

    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    // const [gender, setGender ] = useState('')
    // const [age, setAge] = useState('')
    const [searchInput, setSearchInput] = useState('')

    // Получаем данные с хоста
    const [params, setParams] = useState()
    const [calendar, setCalendar] = useState([])
    const getCalendar = async () => {
        const data = await calendarPage(params, active_lang);
        setCalendar(data)
    }
    useEffect(() => {
        getCalendar();
    },[params]);
    useEffect(() => {
        setParams({
                // category_gender: gender,
                // category_age: age,
                // start_month: month,
                // start_year: year,
                select_month: month,
                select_year: year,
                search: searchInput
        }
        )
    },[ year, month, searchInput])

    const [loadMoreEvents, setLoadMoreEvents] = useState(14);
    const loadMore = () => {
        setLoadMoreEvents(loadMoreEvents + 3);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
      <div className="calendarPage container" ref={ref}>
          <div className="head-menu-calendar">
              <SelectBlue
                  defaultSelectText={langData.year}
                  optionList={yearList}
                  onChange={setYear}
                  customRef={ref}
              />
              <SelectBlue
                  defaultSelectText={langData.month}
                  optionList={monthList}
                  onChange={setMonth}
                  customRef={ref}
              />
              {/*<SelectBlue*/}
              {/*    defaultSelectText={langData.gender}*/}
              {/*    optionList={genderList}*/}
              {/*    onChange={setGender}*/}
              {/*    customRef={ref}*/}
              {/*/>*/}
              {/*<SelectBlue*/}
              {/*    defaultSelectText={langData.age}*/}
              {/*    optionList={ageList}*/}
              {/*    onChange={setAge}*/}
              {/*    customRef={ref}*/}
              {/*/>*/}
              <div className="search">
                  <input
                      className="search-calendar"
                      type="text"
                      placeholder={langData.search}
                      value={searchInput}
                      onChange={(e) => {
                          setSearchInput(e.target.value);
                      }}
                  />
                  <img
                      className="search-calendar_image"
                      src="/images/search.svg"
                      alt="search"
                  />
              </div>
          </div>
          <div className="calendar-page-main">
              <div className="calendar-page_data  calendar-head-text">
                  <span className="calendar-page_name">{langData.title}</span>
                  <span className="calendar-page_date">{langData.date}</span>
                  <span className="calendar-page_city">{langData.city}</span>
              </div>

              {calendar.slice(0, loadMoreEvents).map((element) => (
                      <div className="calendar-page_data calendar-data-text" key={element.id}>
                          <span className="calendar-page_name">
                              <a href={`${element.regulations}`} target={"_blank"}>
                                  {element.translations[active_lang].name} <Info size={15} />
                              </a></span>
                          <span className="calendar-page_date">
                              {`${element.start_date.split('-')[2]}.${element.start_date.split('-')[1]}`} - {element.end_date.split("-").reverse().join(".")}</span>
                          <span className="calendar-page_city">{element.translations[active_lang].location}</span>
                      </div>
              ))}
              <div className={"event-mainPage small-calendar"}>
                  {calendar.slice(0, loadMoreEvents).map((element) => (
                      <CalendarMainPageCard key={element.id} dataEvents={element} />
                  ))}
              </div>
          </div>
          <div className={"btnLoadMore"}>
              <Button
                  className={`${loadMoreEvents >= calendar.length && "disable"}`}
                  onClick={() => loadMore()}
              >
                  {btnMore.btnMore}
              </Button>
          </div>
      </div>
  )
};

export default CalendarPage;

import "./style.css";
import {useState} from "react";

const BtnMainChois = ({ textLeft, onClickLeft, textRight, onClickRight }) => {
    const [red, setRed] = useState(true);
    const leftFn = () => {
        setRed(true)
        onClickLeft()
    }
    const rightFn = () => {
        setRed(false)
        onClickRight()
    }
        return (
    <div className={"btn-blue-red"}>
      <div className={`btn-blue-red_btn ${red && "btnRedColor"}`} onClick={leftFn}>
        <span>{textLeft}</span>
      </div>
      <div className={`btn-blue-red_btn ${!red && "btnRedColor"}`} onClick={rightFn}>
        <span>{textRight}</span>
      </div>
    </div>
  );
};

export default BtnMainChois;

import { languages } from "../../languages";
import "./styles.css";
import {useSelector} from "react-redux";
import ClubCoachCard from "../../components/club-coach-card";
import Button from "../../components/custom-button";
import {useEffect, useState} from "react";
import {coachesPage} from "../../API/api";

const CoachPage = () => {
  const { active_lang } = useSelector((store) => store.language)
    const langData = languages[active_lang].coachesPage
    const btnMore = languages[active_lang]

    const [coaches, setCoaches] = useState([])
    const getCoaches = async () => {
        const data = await coachesPage(active_lang);
        setCoaches(data)
    }
    useEffect(() => {
        getCoaches();
    },[]);

    const [loadMoreCoaches, setLoadMoreCoaches] = useState(3);
    const loadMore = () => {
        setLoadMoreCoaches(loadMoreCoaches + 3);
    };
  return (
      <div className="clubsPage">
            {coaches.slice(0, loadMoreCoaches).map((element) => (
                <ClubCoachCard key={element.id} data={element} dataLang={langData} coaches={true} />
            ))}
          <div className={"btnLoadMore"}>
              <Button
                  className={`${loadMoreCoaches >= coaches.length && "disable"}`}
                  onClick={() => loadMore()}
              >
                  {btnMore.btnMore}
              </Button>
          </div>
      </div>
  )
}

export default CoachPage;

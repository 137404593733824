import { useEffect, useState } from "react";
import { getAboutFederation } from "../../API/api";
import "./federation.css";
import {useSelector} from "react-redux";

const Aboutfd = () => {
  const [about, setAbout] = useState([]);
  const {active_lang} = useSelector((store) => store.language)

  const fetchAbout = async () => {
    getAboutFederation(active_lang).then((res) => setAbout(res));
  };

  useEffect(() => {
    fetchAbout();
  }, []);

  return (
    <div className="aboutfd">
      <div className="container">
        {about.map((element) => (
          <div key={element.id} className="title">
            <h2>{element.translations[active_lang].title}</h2>
            <p>{element.translations[active_lang].text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Aboutfd;

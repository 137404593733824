import "./styles.css";
import React, {useEffect, useState} from "react";
import { footerData } from "../../utils/FooterData/ftData";
import Facebook from "./footerIMG/facebook.png";
import Whatsapp from "./footerIMG/whatsapp.png";
import Instagram from "./footerIMG/instagram.png";
import {useSelector} from "react-redux";
import {languages} from "../../languages";
import {mainPage} from "../../API/api";

const Footer = () => {
  const { active_lang } = useSelector((store) => store.language)
  const langData = languages[active_lang].footer

  const [main, setMain] = useState([])
  const getMainPage = async () => {
    const data = await mainPage(active_lang);
    setMain(data[0])
  }
  useEffect(() => {
    getMainPage();
  },[]);

  const imgStyle = {
    width: "160px",
    height: "120px",
  };
  return (
    <footer>
      <div className="footerSection">
        <div className="footerList container">
          <div className="about">
            <p>{`Copyright © 2022 ${langData.copyright}`}</p>
            <div className="contacts">
              <p>{`${langData.phone}: +${main.whatsapp}`}</p>
              <p>E-mail: kyrgyztennisfederation@gmail.com </p>
              <p>www.ftkr.kg</p>
            </div>
            <p>{langData.disclaimer}</p>
          </div>
          <div className="socialNetworks">
            <a href={`https://wa.me/${main.whatsapp}`} target="_blank" >
              <img src={Whatsapp} alt="WhatsApp" />
            </a>
            <a href={`${main.facebook}`} target="_blank">
              <img src={Facebook} alt="FaceBook" />
            </a>
            <a href={`${main.instagram}`} target="_blank">
              <img src={Instagram} alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

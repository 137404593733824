import { request } from "./service";

// export const token = async () => {
//     if (!localStorage.getItem("Access")) {
//       const result = await accessToken(localStorage.getItem("token"), active_lang)
//       localStorage.setItem('Access', result?.access)
//     }
//   }

export const registerUser = async (data, active_lang) => {
  return await request(`${active_lang}/auth/register/`, "POST", data);
};

export const loginUser = async (data, active_lang) => {
  return await request(`${active_lang}/auth/login/`, "POST", data);
};

export const accessToken = async (token, active_lang) => {
  return await request(`${active_lang}/auth/login/refresh/`, "POST", { refresh: `${token}` });
};

export const logoutUser = async (token, active_lang) => {
  return await request(`${active_lang}/auth/logout/`,
      "POST",
      null,
      null,
      {Authorization: `Bearer ${token}`}
  );
};

export const profilePage = async (token, active_lang) => {
  return await request(
    `${active_lang}/auth/profile/4/`,
    "GET",
    null,
    null,
      {Authorization: `Bearer ${token}`}
  );
};

export const editProfilePage = async (token, data, active_lang, id) => {
  return await request(
      `${active_lang}/auth/profile/${id}/`,
      "PUT",
      data,
      null,
      {Authorization: `Bearer ${token}`}
  );
};

export const changePasswordPage = async (token, data, active_lang, id) => {
  return await request(
    `${active_lang}/auth/change_password/${id}/`,
    "PUT",
    data,
    null,
      {Authorization: `Bearer ${token}`}
  );
};

export const clubPage = async (active_lang) => {
  return await request(
      `${active_lang}/club/`,
      "GET",
  );
};

export const coachesPage = async (active_lang) => {
  return await request(`${active_lang}/trainer/`, "GET");
};

export const newsPage = async (active_lang, params) => {
  return await request(`${active_lang}/news/`,
      "GET",
      null,
      params);
};

export const singleNewsPage = async (id, active_lang) => {
  return await request(`${active_lang}/news/${id}/`, "GET");
};
export const getAboutFederation = async (active_lang) => {
  return await request(`${active_lang}/category/`, "GET");
};

export const calendarPage = async (params, active_lang) => {
  return await request(`${active_lang}/calendar/`, "GET", null, params);
};
export const pastCalendarPage = async (active_lang) => {
  return await request(`${active_lang}/past_calendar/`, "GET");
};
export const currentCalendarPage = async (active_lang) => {
  return await request(`${active_lang}/current_calendar/`, "GET");
};
export const futureCalendarPage = async (active_lang) => {
  return await request(`${active_lang}/future_calendar/`, "GET");
};

export const ratingPage = async (params, active_lang) => {
  return await request(`${active_lang}/rating/`, "GET", null, params);
};

export const galleryPage = async (params, active_lang) => {
  return await request(`${active_lang}/gallery/`, "GET", null, params);
};

export const mainPage = async (active_lang) => {
  return await request(`${active_lang}/main_page/`, "GET");
};

export const searchHeader = async (active_lang, params) => {
  return await request(`${active_lang}/search/${params}`);
};

export const officialpartner = async (active_lang) => {
  return await request(`${active_lang}/official_partner/`, "GET");
};

export const sponsorsandpartners = async (active_lang) => {
  return await request(`${active_lang}/sponsors_and_partners/`, "GET");
};

export const informationalpartners = async (active_lang) => {
  return await request(`${active_lang}/informational_partners/`, "GET");
};
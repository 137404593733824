import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  active_lang: localStorage.getItem("active_lang") || "ru",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.active_lang = action.payload;
      localStorage.setItem("active_lang", action.payload);
    },
  },
});
// Action creators are generated for each case reducer function
export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;

import "./App.css";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/main-page/mainPage";
import PageWrapper from "./layouts/pageWrapper/pageWrapper";
import ClubsPage from "./pages/clubs-page/clubsPage";
import CalendarPage from "./pages/calendar-page/calendarPage";
import CoachPage from "./pages/coach-page/coachPage";
import GalleryPage from "./pages/gallery-page/galleryPage";
import NewsPage from "./pages/news-page/newsPage";
import RatingPage from "./pages/rating-page/ratingPage";
import SingleNewsPage from "./pages/single-news-page/singleNewsPage";
import RegisterPage from "./pages/register-page/registerPage";
import Aboutfd from "./pages/about-federation/federation";
import LoginPage from "./pages/login-page/loginPage";
import ProfilePage from "./pages/profile-page/profilePage";
import EditProfilePage from "./pages/edit-profile-page/editProfilePage";
import {useEffect} from "react";
import SearchPage from "./pages/search-page/searchPage";

function App() {
    // Распаковка токена
    const parseJwt = (token) => {
        let base64Url = token.split(".")[1];
        let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        let jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        return JSON.parse(jsonPayload);
    };
      // Проверка токена на Валидность Refresh
      const tokenActiveRefresh = () => {
          if (localStorage.getItem("token")) {
              const token = parseJwt(localStorage.getItem("token"))
              //console.log(token)
              if (Date.now() >= new Date(token?.exp * 1000)) {
                  localStorage.removeItem("token")
              }
          }
      }

    // Проверка токена на Валидность Access
    const tokenActiveAccess = () => {
        if (localStorage.getItem("Access")) {
            const token = parseJwt(localStorage.getItem("Access"))
            //console.log(token)
            if (Date.now() >= new Date(token?.exp * 1000)) {
                localStorage.removeItem("Access")
            }
        }
    }

  useEffect(() => {
      tokenActiveRefresh()
      tokenActiveAccess()
  }, [])

  return (
    <>
      <Routes>
        <Route element={<PageWrapper />}>
          <Route path={"/"} element={<MainPage />} />
          <Route path={"about"} element={<Aboutfd />} />
          <Route path={"clubs"} element={<ClubsPage />} />
          <Route path={"coaches"} element={<CoachPage />} />
          <Route path={"calendar"} element={<CalendarPage />} />
          <Route path={"rating"} element={<RatingPage />} />
          <Route path={"news"} element={<NewsPage />} />
          <Route path={"news/:id"} element={<SingleNewsPage />} />
          <Route path={"gallery"} element={<GalleryPage />} />
          <Route path={"register"} element={<RegisterPage />} />
          <Route path={"login"} element={<LoginPage />} />
          <Route path={"profile"} element={<ProfilePage />} />
          <Route path={"edit-profile"} element={<EditProfilePage />} />
            <Route path={"search/:query"} element={<SearchPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

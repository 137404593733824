import {useEffect, useState} from "react";
import BtnMainChois from "../../../components/btn-main-chois";
import "../styles.css";
import { useSelector } from "react-redux";
import { languages } from "../../../languages";
import {ratingPage} from "../../../API/api";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";

const MainPageRating = () => {
  // const [age, setAge] = useState();
  // const [name, setName] = useState(MaleData)
    const { active_lang } = useSelector((store) => store.language)
    const langSelectData = languages[active_lang].itemsSelect.ageList
    const langData = languages[active_lang].mainpage.rating

    const [gender, setGender ] = useState('')
    const [age, setAge] = useState('')
    //Get data from host
    const [params, setParams] = useState()
    const [rating, setRating] = useState([])
    //const langRating = rating?.translations?.active_lang
    const getRating = async () => {
        const data = await ratingPage(params, active_lang);
        setRating(data)
    }
    useEffect(() => {
        getRating();
    },[params]);

    useEffect(() => {
        setParams({
            category_gender: gender,
            category_age: age,
            }
        )
    },[gender, age])

    const onLeftClick = () => {
        setGender("male");
    };
    const onRightClick = () => {
        setGender("female");
    };

  return (
    <div className="rating">
      <h2>{langData.title}</h2>
      <div className="aboutRating">
        <BtnMainChois
            textLeft={langData.btnLeft}
            onClickLeft={onLeftClick}
            textRight={langData.btnRight}
            onClickRight={onRightClick}
        />
      <div className="age">
        <div className="ageSelector">
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={age}
                onChange={(event) => {
                    setAge(event.target.value);
                }}
            >
                <FormControlLabel
                    value={langSelectData[2].param}
                    control={<Radio />}
                    label={langData.param2}
                    className="radio-btn-text"
                />
                <FormControlLabel
                    value={langSelectData[1].param}
                    control={<Radio />}
                    label={langData.param}
                    className="radio-btn-text"
                />
            </RadioGroup>
        </div>
      </div>

      {rating.slice(0, 7).map((element) => {
        return (
          <div className="ratingList" key={element.id}>
              <div className="block">
            <div className={"rating"}>{element.rating}</div>
              </div>
              <div className="block">
            <div className={"name"}>{element.translations[active_lang].full_name}</div>
              </div>
              <div className="block">
            <div className={"number"}>{element.points}</div>
              </div>

          </div>
        );
      })}
    </div>
    </div>
  );
};

export default MainPageRating;

export const localPhotos = [
  {
    id: 1,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 2,
    photo:
      "https://ss.sport-express.ru/userfiles/materials/182/1824476/large.jpg",
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 3,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },/*
  {
    id: 4,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://ss.sport-express.ru/userfiles/materials/182/1824476/large.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 5,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 6,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 7,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 8,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 9,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 10,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 11,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 12,
    photos: [
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    ],
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 13,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 14,
    photo:
      "https://ss.sport-express.ru/userfiles/materials/182/1824476/large.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 15,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 16,
    photo:
      "https://ss.sport-express.ru/userfiles/materials/182/1824476/large.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 17,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 18,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 19,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 20,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 21,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 22,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 23,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 24,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 25,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 26,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 27,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 28,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 29,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },
  {
    id: 30,
    photo:
      "https://s5o.sports.ru/storage/simple/ru/edt/d5/2a/ef/a6/ruecea661da30.jpg",
    text: "Теннис России и мира, новости тенниса, онлайн трансляции, видео, результаты 2022 года, статистика, таблицы",
  },*/
];

import './style.css'
import { Edit2 } from "react-feather";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {accessToken, logoutUser, profilePage, token} from "../../API/api";
import {useSelector} from "react-redux";
import {languages} from "../../languages";

const ProfilePage = () => {
    const navigate = useNavigate()
    const tokenChk = () => {
        const token = localStorage.getItem("token")
        if (!token) {
            navigate("/login")
        }
    }
    useEffect(() => {
        tokenChk()
    },[])

    const {active_lang} = useSelector((store) => store.language)
    const langData = languages[active_lang].profilePage;

    const [userData, setUserData] = useState({})
    const getUserDate = async () => {
        // const token = await accessToken(localStorage.getItem("token"), active_lang)
        if (!localStorage.getItem("Access")) {
            const result = await accessToken(localStorage.getItem("token"), active_lang)
            localStorage.setItem('Access', result?.access)
        }
        // await token()
        const user = await profilePage(localStorage.getItem("Access"), active_lang);
        setUserData(user);
    };
    useEffect(() => {
        getUserDate();
    }, []);

    const logout = async () => {
        //const token = await accessToken(localStorage.getItem("token"), active_lang)
        if (!localStorage.getItem("Access")) {
            const result = await accessToken(localStorage.getItem("token"), active_lang)
            localStorage.setItem('Access', result?.access)
        }
        // await token()
        await logoutUser(localStorage.getItem("Access"), active_lang)
        localStorage.removeItem("token")
        localStorage.removeItem("Access")
        navigate("/login")
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="profile container">
            <div className="profile-head">
                <h2>{langData.title}</h2>
                <div className="edit-btn roboto" onClick={() => navigate("/edit-profile")}><Edit2/></div>
                <span onClick={() => logout()}>{langData.exit}</span>
            </div>
            <div className="profile-main">
                <div className="profile-main-left">
                    <span>{langData.name}:</span>
                    <span>{langData.birthdate}:</span>
                    <span>{langData.city}:</span>
                    <span>{langData.gender}:</span>
                    <span>{langData.phone}:</span>
                </div>
                <div className="profile-main-left">
                    <span>{userData?.user?.last_name} {userData?.user?.first_name}</span>
                    <span>{userData?.birthdate}</span>
                    <span>{userData?.city}</span>
                    <span>{userData?.gender === "мужской" ? langData.male : langData.female}</span>
                    <span>{userData?.phone_number}</span>
                </div>
            </div>
        </div>
    )
}

export default ProfilePage
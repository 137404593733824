import { Outlet } from "react-router-dom";
import Footer from "../footer/footer";
import Header from "../header/header";

const PageWrapper = () => {
  return (
    <>
      <Header />
      <div style={{ minHeight: "90vh" }}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
};
export default PageWrapper;

import './style.css'
import {useNavigate} from "react-router-dom";
import {Dialog, DialogTitle, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {useForm} from "react-hook-form";
import {ArrowRight} from "react-feather";
import {useEffect, useState} from "react";
import {accessToken, changePasswordPage, editProfilePage, profilePage, token} from "../../API/api";
import {useSelector} from "react-redux";
import {languages} from "../../languages";

const EditProfilePage = () => {
    //Data state
    const [valueData, setValueData] = useState('')
    const [valueGender, setValueGender] = useState('')
    const [valueEmail, setValueEmail] = useState('')
    const [valueOldEmail, setValueOldEmail] = useState('')
    const [valueLastName, setValueLastName] = useState('')
    const [valueFirstName, setValueFirstName] = useState('')
    const [valueCity, setValueCity] = useState('')
    const [valuePhone, setValuePhone] = useState('')
    const [id, setId] = useState()
    const [id2, setId2] = useState()

    const [errorEmail, setErrorEmail] = useState([]);

    const navigate = useNavigate()
    const {
        formState: {errors}
    } = useForm();
    const {active_lang} = useSelector((store) => store.language)
    const langData = languages[active_lang].editProfilePage
    const langChangePass = languages[active_lang].changePassword

    const tokenChk = () => {
        const token = localStorage.getItem("token")
        if (!token) {
            navigate("/login")
        }
    }
    useEffect(() => {
        tokenChk()
    },[])

    //Получаем данные
    const getUserDate = async () => {
        //const token = await accessToken(localStorage.getItem("token"), active_lang)
        if (!localStorage.getItem("Access")) {
            const result = await accessToken(localStorage.getItem("token"), active_lang)
            localStorage.setItem('Access', result?.access)
        }
        // await token()
        const data = await profilePage(localStorage.getItem("Access"), active_lang);
        setValueData(new Date(data?.birthdate))
        setValueGender(data?.gender)
        setValueEmail(`${data?.email}`)
        setValueOldEmail(`${data?.email}`)
        setValueLastName(`${data?.user.last_name}`)
        setValueFirstName(`${data?.user.first_name}`)
        setValueCity(`${data?.city}`)
        setValuePhone(data?.phone_number)
        setId(data?.id)
        setId2(data?.user.id)
    };
    useEffect(() => {
            getUserDate();
    }, []);

    const onSubmit = async () => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            let data = {}
            let user = {}
            user.id = id2
            // if (valueEmail !== valueOldEmail) {
            //     user.email = valueEmail
            // }
            data.email = valueEmail
            user.last_name = valueLastName
            user.first_name = valueFirstName
            data.id = id
            data.user= user
            data.city = valueCity
            const newDate = new Date(valueData).setMilliseconds(6 * 60 * 60 * 1000)
            data.birthdate = new Date(newDate).toISOString().slice(0, 10);
            data.gender = valueGender;
            data.phone_number = Number(valuePhone)
            //const token = await accessToken(localStorage.getItem("token"), active_lang)
            //console.log(data)
            // await token()
            if (!localStorage.getItem("Access")) {
                const result = await accessToken(localStorage.getItem("token"), active_lang)
                localStorage.setItem('Access', result?.access)
            }
            await editProfilePage(localStorage.getItem("Access"), data, active_lang, id);
            /*reset()*/
            navigate("/profile")
        } catch (error) {
            console.dir(error);
            setErrorEmail(JSON.parse(error.request.responseText).email);
            throw new Error("Что-то не так с пост запросом");
        }
    };

    const cancelForm = () => {
        navigate(-1)
    }

    //  Тут фунции для модалки смены пароля
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [valueOldPassword, setValueOldPassword] = useState('')
    const [valuePassword1, setValuePassword1] = useState('')
    const [valuePassword2, setValuePassword2] = useState('')
    const [errorPassword, setErrorPassword] = useState('')
    const [errorNewPassword, setErrorNewPassword] = useState([])

    const handleClickOpenChangePassword = () => {
        setOpenChangePassword(true);
    };

    const handleCloseChangePassword = (value) => {
        setOpenChangePassword(false);
        setValueOldPassword('')
        setValuePassword1('')
        setValuePassword2('')
    };

    const passwordCheck = () => {
        if (valuePassword1 !== valuePassword2 && valuePassword2.length > 0) {
            return false
        } else {
            return true
        }
    }

    const changePassword = async () => {
        if (errors?.name) {
            console.log(errors?.name?.message);
        }
        try {
            const dataPass = {}
            if (!localStorage.getItem("Access")) {
                const result = await accessToken(localStorage.getItem("token"), active_lang)
                localStorage.setItem('Access', result?.access)
            }
            // await token()
            //const token = await accessToken(localStorage.getItem("token"), active_lang)
            dataPass.old_password = valueOldPassword
            dataPass.password= valuePassword1
            dataPass.password2 = valuePassword2
            //console.log(dataPass);
            await changePasswordPage(localStorage.getItem("Access"), dataPass, active_lang, id2);
            setOpenChangePassword(false)
            setValueOldPassword('')
            setValuePassword1('')
            setValuePassword2('')
        } catch (error) {
            console.dir(error);
            setErrorPassword(JSON.parse(error.request.responseText).old_password.old_password)
            setErrorNewPassword(JSON.parse(error.request.responseText).password)
            //console.log(error.request.responseText)
            throw new Error("Что-то не так с пост запросом");
        }
    };

    return (
        <div className="edit-profile container">
            <div className="edit-profile-head">
                <h2>{langData.title}</h2>
                <span onClick={handleClickOpenChangePassword}>{langData.changePass}</span>
            </div>
            {/*<form >*/}
                <div className="edit-profile-main">
                    <TextField
                        className="register-form-input"
                        type="email"
                        label="E-mail"
                        variant="outlined"
                        value={valueEmail}
                        onChange={(e) => {
                            setValueEmail(e.target.value);
                        }}
                        error={errorEmail.length > 0}
                        helperText={errorEmail ? errorEmail.map((er) => er) : ""}
                    />
                    <div className="register-wrap">
                        <TextField
                            className="register-form-input"
                            type="text"
                            label={langData.lastName}
                            variant="outlined"
                            value={valueLastName}
                            onChange={(e) => {
                                setValueLastName(e.target.value);
                            }}
                        />
                        <TextField
                            className="register-form-input"
                            type="text"
                            label={langData.firstName}
                            variant="outlined"
                            value={valueFirstName}
                            onChange={(e) => {
                                setValueFirstName(e.target.value);
                            }}
                        />
                        <TextField
                            className="register-form-input"
                            type="text"
                            label={langData.city}
                            variant="outlined"
                            value={valueCity}
                            onChange={(e) => {
                                setValueCity(e.target.value);
                            }}
                        />
                    </div>
                    <div className="register-date">
                        <LocalizationProvider adapterLocale={'ru'} dateAdapter={AdapterDayjs}>
                            <DatePicker
                                openTo="year"
                                views={["year", "month", "day"]}
                                label={langData.birthdate}
                                className="register-text"
                                value={valueData}
                                onChange={(newValue) => {
                                    setValueData(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField  {...params} helperText={null} />
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="register-radio">
                        <FormLabel id="demo-row-radio-buttons-group-label" className="register-text">{langData.gender}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={valueGender}
                            onChange={(event) => {
                                setValueGender(event.target.value);
                            }}
                        >
                            <FormControlLabel value="мужской" control={<Radio />} label={langData.male} />
                            <FormControlLabel value="женский" control={<Radio />} label={langData.female} />
                        </RadioGroup>
                    </div>
                    <div className="register-wrap">
                        <TextField
                            className="register-form-input"
                            type="number"
                            label={langData.contactphone}
                            variant="outlined"
                            value={valuePhone}
                            onChange={(e) => {
                                setValuePhone(e.target.value);
                            }}
                            onWheel={(e) => e.target.blur()}
                            InputLabelProps={{
                            shrink: true,
                        }}
                        />
                    </div>
                </div>
                <div className="edit-profile-btn">
                    <button className={`edit-profile-btn-add`} onClick={() => onSubmit()} >
                        {langData.save} <ArrowRight width="16px" height="12px"/>{" "}
                    </button>
                    <div className={`edit-profile-btn-cancel`} onClick={() => cancelForm()} >{langData.cancel}</div>
                </div>
            {/*</form>*/}


            {/* MOdal Change Password */}
            <Dialog onClose={handleCloseChangePassword} open={openChangePassword}>
                <DialogTitle className="modal-changePassword-Title">{langChangePass.title}</DialogTitle>
                    <div className="modal-changePassword">
                        <TextField
                            className="register-form-input"
                            type="password"
                            label={langChangePass.password}
                            variant="outlined"
                            value={valuePassword1}
                            onChange={(e) => {
                                setValuePassword1(e.target.value);
                            }}
                            error={errorNewPassword.length > 1}
                            helperText={errorNewPassword?.map((er)=> er)}
                        />
                        {/*<input type="password" {...register-page("password", { required: true })} placeholder="пароль"/>*/}
            <TextField
                            className="register-form-input"
                            type="password"
                            label={langChangePass.password2}
                            variant="outlined"
                            error={!passwordCheck()}
                            helperText={passwordCheck() ? "" : `${langChangePass.password2Err}`}
                            value={valuePassword2}
                            onChange={(e) => {
                                setValuePassword2(e.target.value);
                            }}
                        />
                        <TextField
                            className="register-form-input"
                            type="password"
                            label={langChangePass.oldPassword}
                            variant="outlined"
                            value={valueOldPassword}
                            onChange={(e) => {
                                setValueOldPassword(e.target.value);
                            }}
                            error={errorPassword.length >1}
                            helperText={`${errorPassword}`}
                        />
                    </div>
                    <div className="edit-profile-btn">
                        <button className={`edit-profile-btn-add`} onClick={() => changePassword()} >
                            {langChangePass.save} <ArrowRight width="16px" height="12px"/>{" "}
                        </button>
                        <div className={`edit-profile-btn-cancel`} onClick={handleCloseChangePassword} >{langChangePass.cancel}</div>
                    </div>
            </Dialog>
        </div>
    )
}

export default EditProfilePage
import "./style.css"
import {useSelector} from "react-redux";

const ClubCoachCard = ({data, dataLang, coaches = false}) => {
    const { active_lang } = useSelector((store) => store.language)
    return (
        <div className="club-coaches-cart container">
            <div className="club-coaches-cart_text">
                <span>{data.translations[active_lang].name}</span>
                <p>{data.translations[active_lang].description}</p>
            </div>
            <div className="club-coaches-cart_info">
                <div className="club-coaches-cart_info_left">
                    {dataLang.map((element) => (
                        <p key={element}>{element}</p>
                    ))}
                </div>
                <div className="club-coaches-cart_info_right">
                    <div className={`club-coaches-cart_info_string ${coaches ? "display-none" : ""}`}>
                        <a href={data.address_link} target="_blank">{data.translations[active_lang].address}</a>
                    </div>
                    <div className={`club-coaches-cart_info_string ${!coaches ? "display-none" : "" }`}>{data.translations[active_lang].address}</div>
                    <div className={`club-coaches-cart_info_string`}>
                        {data.translations[active_lang].contacts}
                    </div>
                    <div className={`club-coaches-cart_info_string ${coaches ? "display-none" : ""}`}>{data.translations[active_lang].working_hours}</div>
                    <div className={`club-coaches-cart_info_string ${coaches ? "display-none" : ""}`}>
                        <a href={data.instagram} target="_blank">Instagram </a>/
                        <a href={data.facebook} target="_blank"> Facebook</a>
                    </div>
                    <div className={`club-coaches-cart_info_string ${coaches ? "" : "display-none"}`}>{data.translations[active_lang].category}</div>
                </div>
            </div>
            <div className="club-coaches-cart_image">
                <img alt="img" src={data.images} />
            </div>
        </div>
    )
}

export default ClubCoachCard
import "./style.css";

import React, {useEffect, useState} from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import SliderGalleryCard from "../../components/slider-gallery-card";

import { localPhotos } from "../../utils/mocked-gallery";
import { languages } from "../../languages";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {galleryPage} from "../../API/api";

const MainGallerySlider = () => {
  const ref = React.useRef();
  const navigate = useNavigate();

  const { active_lang } = useSelector((store) => store.language);
  const langData = languages[active_lang].mainpage.gallery;

    const params = {ordering: "-date"}
    const [gallery, setGallery] = useState([])
    // const [maxVisibleSlide, setMaxVisibleSlide] = useState(1)
    const getGallery = async () => {
        const data = await galleryPage(params, active_lang);
        setGallery(data)
    }
    useEffect(() => {
        getGallery();
    },[]);
    useEffect(() => {
        if (gallery.length > 0) {
            if (gallery?.length < 5) {
                gallery.map((el) => {
                    setGallery(oldArray => [...oldArray, el])
                })
            }
        }

    }, [gallery])
    // console.log(gallery)
  return (
    <div className="mainPageGallery container">
      <h2>{langData.title}</h2>
      <div className={"allPhoto"} onClick={() => navigate("/gallery")}>
        {langData.allPhoto}
      </div>
      <div style={{ width: "100%", position: "relative", marginTop: "50px" }}>
        <ResponsiveContainer
          carouselRef={ref}
          render={(parentWidth, carouselRef) => {
            // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
            // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
            // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
            // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
            let currentVisibleSlide = 5;
            if (parentWidth <= 1080) currentVisibleSlide = 3;
            if (parentWidth <= 700) currentVisibleSlide = 1;

            return (
              <StackedCarousel
                ref={carouselRef}
                slideComponent={SliderGalleryCard}
                slideWidth={parentWidth < 800 ? parentWidth - 40 : 450}
                carouselWidth={parentWidth}
                data={gallery?.length > 0 ? gallery : localPhotos}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
                disableSwipe={false} // Запрет. Прокрутка мышкой//
              />
            );
          }}
        />
        <>
          <div
            style={{ left: 10 }}
            //size="small"
            // color="#02154F"
            onClick={() => {
              ref.current?.goBack();
            }}
            className="nav-btn-slider"
          >
            <ArrowLeftCircle
              color="#fff"
              fill="#02154F"
              width="55"
              height="55"
              strokeWidth="1"
            />
          </div>
          <div
            style={{ right: 10 }}
            //size="small"
            // color="#02154F"
            onClick={() => {
              ref.current?.goNext(6);
            }}
            className="nav-btn-slider"
          >
            <ArrowRightCircle
              color="#fff"
              fill="#02154F"
              width="55"
              height="55"
              strokeWidth="1"
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default MainGallerySlider;

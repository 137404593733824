import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/ru";

import { ArrowRight } from "react-feather";
import { useForm } from "react-hook-form";
import "./styles.css";
import { useRef } from "react";
import { registerUser } from "../../API/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { languages } from "../../languages";

const RegisterPage = () => {
  const { active_lang } = useSelector((store) => store.language);
  const langData = languages[active_lang].registerForm;

  const [valueData, setValueData] = useState("");
  const [valueGender, setValueGender] = useState("");
  const [valuePassword1, setValuePassword1] = useState("");
  const [valuePassword2, setValuePassword2] = useState("");

  const navigate = useNavigate();

  const passwordCheck = () => {
    if (valuePassword1 !== valuePassword2 && valuePassword2.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const dataGenderCheck = () => {
    if (
      valueData &&
      valueGender.length > 0 &&
      passwordCheck() &&
      valuePassword2
    ) {
      return false;
    } else {
      return true;
    }
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const DateValue = useRef();

  const [errorUserName, setErrorUserName] = useState([]);
  const [errorPassword, setErrorPassword] = useState([]);
  const [errorEmail, setErrorEmail] = useState([]);

  const onSubmit = async (data) => {
    if (errors?.name) {
      console.log(errors?.name?.message);
    }
    try {
      data.player.birthdate = new Date(valueData.$d).toISOString().slice(0, 10);
      data.player.gender = valueGender;
      data.player.phone_number = Number(data.player.phone_number);
      //console.log(data);
      await registerUser(data, active_lang);
      reset();
      navigate("/login");
    } catch (error) {
      console.dir(error);
      setErrorUserName(JSON.parse(error.request.responseText).username);
      setErrorPassword(JSON.parse(error.request.responseText).password);
      setErrorEmail(JSON.parse(error.request.responseText).player.email);
      console.log(error.request.responseText);
      throw new Error(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="register container">
      <h2>{langData.title}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="register-form">
          <TextField
            className="register-form-input"
            type="text"
            label={langData.userName}
            variant="outlined"
            {...register("username", { required: true })}
            error={errorUserName?.length}
            helperText={
              errorUserName ? errorUserName.map((er) => er + "\n") : ""
            }
          />
          {/*<input type="text" {...register-page("username", { required: true })} placeholder="имя пользователя" />*/}
          <TextField
            className="register-form-input"
            type="email"
            label="E-mail"
            variant="outlined"
            {...register("player.email", { required: true })}
            error={errorEmail?.length}
            helperText={errorEmail ? errorEmail.map((er) => er) : ""}
          />
          {/*<input type="email" {...register-page("email", { required: true })} placeholder="E-mail"/>*/}
          <div className="register-wrap">
            <TextField
              className="register-form-input"
              type="password"
              label={langData.password}
              variant="outlined"
              {...register("password", { required: true })}
              value={valuePassword1}
              onChange={(e) => {
                setValuePassword1(e.target.value);
              }}
              error={errorPassword?.length}
              helperText={errorPassword ? errorPassword.map((er) => er) : ""}
            />
            {/*<input type="password" {...register-page("password", { required: true })} placeholder="пароль"/>*/}
            <TextField
              className="register-form-input"
              type="password"
              label={langData.password2}
              variant="outlined"
              {...register("password2", { required: true })}
              error={!passwordCheck()}
              helperText={passwordCheck() ? "" : `${langData.password2Err}`}
              value={valuePassword2}
              onChange={(e) => {
                setValuePassword2(e.target.value);
              }}
            />
            {/*<input
              type="password"
              {...register-page("password2", { required: true })}
              placeholder="ПОВТОРИТЬ пароль"
            />*/}
            <TextField
              className="register-form-input"
              type="text"
              label={langData.lastName}
              variant="outlined"
              {...register("last_name", { required: true })}
            />
            {/*<input type="text" {...register-page("last_name", { required: true })} placeholder="Фамилия"/>*/}
            <TextField
              className="register-form-input"
              type="text"
              label={langData.firstName}
              variant="outlined"
              {...register("first_name", { required: true })}
            />
            {/*<input type="text" {...register-page("first_name", { required: true })} placeholder="Имя"/>*/}
            <TextField
              className="register-form-input"
              type="text"
              label={langData.city}
              variant="outlined"
              {...register("player.city", { required: true })}
            />
            {/*<input type="text" {...register-page("player.city", { required: true })} placeholder="город"/>*/}
          </div>
          <div className="register-date">
            <LocalizationProvider
              adapterLocale={"ru"}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                openTo="year"
                views={["year", "month", "day"]}
                label={langData.birthdate}
                value={valueData}
                className="register-text"
                onChange={(newValue) => {
                  setValueData(newValue);
                }}
                renderInput={(params) => (
                  <TextField ref={DateValue} {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className="register-radio">
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="register-text"
            >
              Пол
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={valueGender}
              onChange={(event) => {
                setValueGender(event.target.value);
              }}
            >
              <FormControlLabel
                value="мужской"
                control={<Radio />}
                label={langData.male}
              />
              <FormControlLabel
                value="женский"
                control={<Radio />}
                label={langData.female}
              />
            </RadioGroup>
          </div>
          <div className="register-wrap">
            <TextField
              className="register-form-input"
              type="number"
              label={langData.phoneNumber}
              variant="outlined"
              {...register("player.phone_number", { required: true })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/*<input type="number" {...register-page("player.phone_number", { required: true })} placeholder="Контактный номер"/>
            <input type="text" {...register-page("player.phone_number[1]", { required: true })} placeholder="Контактный номер"/>*/}
          </div>

          <button
            className={`register-btn-add ${dataGenderCheck() && "disable"}`}
            disabled={dataGenderCheck()}
          >
            {langData.register} <ArrowRight width="16px" height="12px" />{" "}
          </button>
        </div>
      </form>
      <div className="register-enter">
        <span>{langData.registerEnter}</span>
        <div className="register-enter-btn" onClick={() => navigate("/login")}>
          {langData.enter} <ArrowRight width="16px" height="12px" />{" "}
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;

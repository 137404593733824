import { useNavigate } from "react-router-dom";
import "./style.css";
import {useSelector} from "react-redux";

const NewsCard = ({ dataNews, ratio, bgWhite = false, short = false }) => {
  const navigate = useNavigate();
  const { active_lang } = useSelector((store) => store.language);
  return (
    <div
      className={`news-card ${bgWhite && "bgWhite"}`}
      onClick={() => navigate(`/news/${dataNews.id}`)}
    >
      <img
        style={{ aspectRatio: ratio }}
        src={dataNews.main_photo}
        alt="element"
      />
      <div className="news-card_text">
        <div className={`news-card-info ${short && "display-none"}`}>
          {dataNews.date}
        </div>
        <span>{dataNews.translations[active_lang].name}</span>
        <div className={`news-card-info author ${short && "display-none"}`}>
          {dataNews.translations[active_lang].source}
        </div>
      </div>
    </div>
  );
};

export default NewsCard;

import { useEffect } from "react";
import { useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { setGalleryId } from "../../store/modal";
import { localPhotos } from "../../utils/mocked-gallery";
import "./style.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
const Modal = () => {
  const [photos, setPhotos] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const { gallery_id } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const closeModal = () => {
    setPhotos([]);
    dispatch(setGalleryId(null));
  };

  useEffect(() => {
    if (gallery_id) {
      setPhotos(gallery_id?.gallery_image || []);
    }
  }, [gallery_id]);

  return (
    <div className={gallery_id ? "modal active" : "modal"}>
      <div className="icon" onClick={closeModal}>
        <X />
      </div>
      <div className={gallery_id ? "modal__content active" : "modal_content"}>

        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={photo.images}
                alt="img"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                src={photo.images}
                alt="img"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Modal;

import "./styles.css";
import NewsCard from "../../components/news-card";
import Button from "../../components/custom-button";
import { useEffect, useState } from "react";

import {useSelector} from "react-redux";
import {languages} from "../../languages";
import {newsPage} from "../../API/api";

const NewsPage = () => {
    const { active_lang } = useSelector((store) => store.language)
    const langData = languages[active_lang].newsPage
    const btnMore = languages[active_lang]

    const params = {ordering: "-date"}
    const [news, setNews] = useState([])
    const getNews = async () => {
        const data = await newsPage(active_lang, params);
        setNews(data)
    }
    useEffect(() => {
        getNews();
    },[]);

  const [loadMoreNews, setLoadMoreNews] = useState(11);
  const loadMore = () => {
    setLoadMoreNews(loadMoreNews + 3);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="newsPage container">
      <h2>{langData.news}</h2>
      <div className="news-cards">
        {news.slice(0, 2).map((element) => (
          <NewsCard key={element.id} dataNews={element} ratio={"6/4"} />
        ))}
      </div>
      <div className="news-cards-small">
        {news.slice(2, loadMoreNews).map((element) => (
          <NewsCard key={element.id} dataNews={element} ratio={"4/3"} />
        ))}
      </div>
      <div className={"btnLoadMore"}>
        <Button
          className={`${loadMoreNews >= news.length && "disable"}`}
          onClick={() => loadMore()}
        >
            {btnMore.btnMore}
        </Button>
      </div>
    </div>
  );
};

export default NewsPage;

import "./styles.css";
import MainPageCalendar from "./calendar/main-page-calendar";
import MainPageRating from "./rating/main-page-rating";

const MainPageRatingCalendar = () => {
  return (
    <section className="ratingCalendarSection">
      <div className="mainPageRatingCalendar container">
        <MainPageRating />
        <MainPageCalendar />
      </div>
    </section>
  );
};

export default MainPageRatingCalendar;


import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../store/language";
import { languages } from "../../languages";
import Menu from "./Menu/menu"
import {useEffect, useState} from "react";
import {mainPage} from "../../API/api";
import {useRef} from "react";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false)
  const [search, setSearch] = useState()
  const dispatch = useDispatch();
  const { active_lang } = useSelector((store) => store.language);
  const { header } = languages[active_lang];
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  const ref = useRef();

  const searchRedirect = (val) => {
    if (val.length > 0) {
      navigate(`/search/${val}`)
      setSearch("")
    }
  }

  const [main, setMain] = useState([])
  const getMainPage = async () => {
    const data = await mainPage(active_lang);
    setMain(data[0])
  }

  useEffect(() => {
    getMainPage();
  },[]);

  useEffect(() => {
    const onKeyDown = e => {
      if(e.keyCode === 13 && document.getElementById('input-search') === document.querySelector(':focus')) {
        searchRedirect(e.target.value);
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  //console.log(search)
  return (
    <header>
      <div className="container">
        <div className="header-top">
          <div className="logo-block" onClick={() => navigate("/")}>
            <p className="logo_text">
              {header.logo_text}
            </p>
            <img className="logo_image" src="/images/logo.svg" alt="LOGO" />
          </div>
          <div className="search-and-lang">
            <div className="search">
              <input
                  className="input"
                  id="input-search"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
              />
              <img
                className="search_image"
                src="/images/search.svg"
                alt="search"
                onClick={() => searchRedirect(search)}
              />
            </div>
            <select
              className="change_lang"
              onChange={(e) => dispatch(changeLanguage(e.target.value))}
              defaultValue={active_lang}
            >
              <option value="ru">RU</option>
              <option value="ky">KG</option>
              <option value="en">ENG</option>
            </select>
            <div ref={ref}>
              <div className="burger-btn" onClick={() => setMenuActive(!menuActive)}>
                <span/>
              </div>
              <Menu active={menuActive} setActive={setMenuActive} customRef={ref}/>
            </div>
          </div>
          <div className="login_right_mainpage">
            <div className="login__mainpage">
              <img className="human" src="/images/human.svg" alt="human" onClick={() => navigate("/login")} />

              <button className="come" onClick={() => navigate("/login")}>{token ? header.profile : header.login}</button>
            </div>

            <a href="https://www.itftennis.com/en/" target="_blank"><img alt="logo_itf" height="40px" src="/images/ITF-logo.png"/></a>
            <a href="https://atf.tournamentsoftware.com/" target="_blank"><img alt="logo_atf" height="47px" src="/images/ATF-logo.svg"/></a>
          </div>
        </div>
        <div className="header-bottom">
          <nav className="links">
            {Object.keys(header.navigation).map((key, index) => {
              return (
                <Link className={"links-header"} key={index} to={`/${key}`}>
                  {header.navigation[key]}
                </Link>
              );
            })}
          </nav>
          <div className="social_network">
            <a href={`https://wa.me/${main.whatsapp}`} target="_blank" >
              <img src="/images/whatsapp.svg" alt="WhatsApp" />
            </a>
            <a href={`${main.facebook}`} target="_blank">
              <img src="/images/facebook.svg" alt="FaceBook" />
            </a>
            <a href={`${main.instagram}`} target="_blank">
              <img src="/images/instagram.svg" alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
      {/*</div>*/}
      {/*  <Menu active={menuActive} setActive={setMenuActive} customRef={ref}/>*/}

    </header>

  );
};

export default Header;

import "./style.css";
import {useDispatch, useSelector} from "react-redux";
import {setGalleryId} from "../../store/modal";

const GalleryCard = ({ dataGall, ratio, bgWhite = false }) => {
  const { active_lang } = useSelector((store) => store.language);
  const dispatch = useDispatch();
  return (
    <div
      className={`gallery-card ${bgWhite && "bgWhite"}`}
      onClick={() => dispatch(setGalleryId(dataGall))}
    >
      <img style={{ aspectRatio: ratio }} src={dataGall.main_image} alt="element" />{" "}
      <div className="news-card_text">
        <span>{dataGall.translations[active_lang].title}</span>
      </div>
    </div>
  );
};

export default GalleryCard;

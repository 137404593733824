import "./styles.css";
import GalleryCard from "../../components/gallery-card";
import Button from "../../components/custom-button";
import { useEffect, useState } from "react";
import { localPhotos } from "../../utils/mocked-gallery";
import {galleryPage} from "../../API/api";
import {useSelector} from "react-redux";
import {languages} from "../../languages";

const GalleryPage = () => {
  const [loadMoreGall, setLoadMoreGall] = useState(12);
  const loadMore = () => {
    setLoadMoreGall(loadMoreGall + 3);
  };

    const { active_lang } = useSelector((store) => store.language);
    const langData = languages[active_lang].mainpage.gallery;
    const btnMore = languages[active_lang]

    const params = {ordering: "-date_added"}
    const [gallery, setGallery] = useState([])
    const getGallery = async () => {
        const data = await galleryPage(params, active_lang);
        setGallery(data)
    }
    useEffect(() => {
        getGallery();
    },[]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    console.log(gallery)

  return (
    <div className="GallPage container">
      <h2>{langData.title}</h2>
      <div className="gall-cards">
        {gallery.slice(0, loadMoreGall).map((element) => (
          <GalleryCard key={element.id} dataGall={element} />
        ))}
      </div>

      <div className={"btnLoadMore"}>
        <Button
          className={`${loadMoreGall >= localPhotos.length && "disable"}`}
          onClick={() => loadMore()}
        >
            {btnMore.btnMore}
        </Button>
      </div>
    </div>
  );
};

export default GalleryPage;
